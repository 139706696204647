
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import VuePaystack from "vue-paystack2";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "buy-insurance-cart",
  components: { VuePaystack },
  props: ["publicId"],
  data() {
    return {
      isVisible: false,
      displayPageRedirection: false,
      displaySuccessMessage: false,
      loader: false,
      cartDetails: "",
      reference: "",
      paystackkey: process.env.VUE_APP_PAYSTACK_KEY,
      address: "",
      policyId: "",
      code: "",
      response: {},
      role: variables.INDIVIDUAL_CLIENT_USER_ROLE,
    };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Invoice", ["Buy Insurance", "Cart"]);
    });

    return { user };
  },
  created() {
    // if (this.user.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
    //   this.hasPolicyPeriod();
    // }

    this.getCartDetails();

    setTimeout(() => {
      this.hasPersonalInformation();
    }, 2000);
    this.reference = this.generateUniqueReference();

    //Set page title
    document.title = "Invoice | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasPolicyPeriod() {
      const router = useRouter();

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          variables.CART_ITEM_ROUTE + this.publicId + "/policy-period"
        )
          .then(({ data }) => {
            //Assign data to form fields
            if (!data.data.hasPolicyPeriod) {
              router.push({
                name: "insurance-packages-policy-period",
                params: {
                  publicId: this.publicId,
                },
              });
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    hasPersonalInformation() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.VERIFY_CONTACT_INFORMATION_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            if (!data.data.hasPersonalInformation || this.address == "") {
              this.displayPageRedirection = true;
            } else {
              this.displayPageRedirection = false;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getCartDetails: function() {
      const router = useRouter();

      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.CART_ROUTE + "/" + this.publicId)
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.cartDetails = data.data;
            this.address = data.data.address;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    printPage(el) {
      variables.printPage(el);
    },
    generateUniqueReference() {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 15; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    callback: function(response) {
      this.loader = true;
      const payload = {
        provider: "paystack",
        reference: response.reference,
        amount: this.cartDetails["premium"],
        cartItemId: this.publicId,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(variables.PAYMENT_ROUTE, payload)
          .then((response) => {
            this.loader = false;
            // console.log(response.data.data);
            variables.toastAlert(response.data.data.message, "success");
            this.displaySuccessMessage = true;
            this.code = response.data.data.businessTypeShortCode;
            this.policyId =
              response.data.data.policyId != ""
                ? response.data.data.policyId
                : response.data.data.policyPublicId;
            this.response = response.data.data;

            store.commit(Mutations.CART_MUTATION, response.data.data.cart);
            store.commit(
              Mutations.NOTIFICATION_MUTATION,
              response.data.data.notifications
            );

            // this.$router.push({ name: "client-policy" });
          })
          .catch(function(error) {
            console.log(error);
            // variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    close: function() {
      variables.toastAlert(
        "Transaction was not completed. Payment window closed!",
        "success"
      );
      this.isVisible = false;
    },
    redirect(value) {
      let route = "";

      switch (value) {
        case ["HOM-HOU-OWN", "HOM-HOU", "HOM-HOU-OWN-", "HOM-HOU-"].includes(
          value
        ):
          route = "owned-building-house-policy-information";
          break;
        case "HOM-HOU-TEN":
          route = "rented-building-house-policy-information";
          break;
        case "HOM-HOU-CON":
          route = "contents-house-policy-information";
          break;
        case "HOM-HOU-HPA":
          route = "personal-accident-house-policy-information";
          break;
        case "MOT":
          route = "motor-insurance-policy-information";
          break;
        default:
          route = "";
      }

      return route;
    },
  },
});
